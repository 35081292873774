import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { GET_SIGN_URL } from '../graphql/common';

export default function useSignS3URL(path, file, callback, options) {
  const [mutateSignUrl, { data, loading: mutationLoading, error: mutationError }] = useMutation(
    GET_SIGN_URL,
    {
      // fetchPolicy: 'no-cache',
      onCompleted: (response) => {
        const url = response.signS3.signedRequest;
        if (callback) callback(url);
      },
    }
  );

  console.log(options);

  const getSignUrl = useCallback(() => {
    mutateSignUrl({
      variables: {
        filename: `${path ? `${path}/` : ''}` + file.name,
        ...options,
      },
    });
  }, [mutateSignUrl]);

  return [getSignUrl, data?.signS3, mutationLoading];
}
