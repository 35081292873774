import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';

import * as Styled from './PickLayout.styles';
import { createUrlWithQuery } from 'utils/createUrlWithQuery';
import { GetEventCategories } from 'graphql/philingEvents';
import BottomNavMenu from 'v2/component/common/bottom-nav';

const ArrowDown = '/assets/images/icons/arrow-down.svg';

export default function PickLayout({ children, payload }) {
  const router = useRouter();
  const { categoryId } = router.query;

  const { data } = useQuery(GetEventCategories);
  const eventCategories = data ? data.eventCategories : [];

  const paths = router.pathname.split('/');
  const isPackage = paths.indexOf('package') !== -1;

  const pickUrl = createUrlWithQuery({ query: router.query, url: '/pick' });
  const packageUrl = createUrlWithQuery({ query: router.query, url: '/pick/package' });

  return (
    <div>
      <Styled.TextWrapper>
        <Styled.Title>{payload.title}</Styled.Title>
        <Styled.Desc>{payload.desc}</Styled.Desc>
      </Styled.TextWrapper>
      <Styled.CategoryWrapper>
        <Styled.ImgArrowDown src={ArrowDown} />
        <Styled.SelectBox
          value={categoryId}
          placeholder="치료과목"
          onChange={(e) => {
            if (e.target.value === 'all') {
              if (isPackage) {
                router.push(packageUrl);
              } else {
                router.push(pickUrl);
              }
            } else {
              if (isPackage) {
                router.push(
                  createUrlWithQuery({
                    query: router.query,
                    url: `/pick/package/category/${e.target.value}`,
                  })
                );
              } else {
                router.push(
                  createUrlWithQuery({
                    query: router.query,
                    url: `/pick/category/${e.target.value}`,
                  })
                );
              }
            }
          }}
        >
          <option value="all">전체</option>
          {eventCategories.map((value) => (
            <option key={value.id} value={value.id}>
              {value.name}
            </option>
          ))}
        </Styled.SelectBox>
      </Styled.CategoryWrapper>
      <main>{children}</main>
      <BottomNavMenu />
    </div>
  );
}
